const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://t3arhfbquj.execute-api.eu-west-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://qn8l2uz0xj.execute-api.eu-west-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.5',
    HOSTNAME: 'https://teams-service.staging.europe.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.europe.forwoodsafety.com',
    WEBSOCKET: 'wss://o2xqzbbzq1.execute-api.eu-west-1.amazonaws.com/staging'
  },
};

export default config;
